import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import dvImage from "../assets/images/dvImage.svg";

const Home = () => {
	return <>
	<Box
				
				sx={{
					bgcolor: "black",
					borderRadius: 7.5,
					boxShadow: 3,
					mt: 4,
					display: "flex",
					alignItems: "center",
				}}
				style={{
					height: "63hv",
					width: "78vw",
					margin: "auto",
					marginTop: "4vh",
					marginBottom: "10vh",
				}}
			>
				<Box
					sx={{
						width: "90%",
						height: "100%",
						backgroundColor: "black",
						color: "white",
						p: 3,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography variant="h3" sx={{ fontWeight: "bold" }}>
						datavant
					</Typography>
					<Typography variant="h4" sx={{ fontWeight: "bold", mt: 2 }}>
						Connector Activation
					</Typography>
					
				
				</Box>
				<Box
					sx={{
						width: { xs: "100%", md: "210px", lg: "502px", xl: "700px" },
						height: { xs: "auto", md: "63vh" },
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderTopRightRadius: 29.5,
						borderBottomRightRadius: 29.5,
						backgroundImage: `url(${dvImage})`,
						backgroundSize: "cover",
						backgroundPosition: "right",
					}}
				>
				</Box>
			</Box></>;
};

export default Home;
