import React from "react";
import { Box, Typography } from "@mui/material";

function ErrorPage() {
    return (
        <Box
				
        sx={{
            bgcolor: "black",
            borderRadius: 7.5,
            boxShadow: 3,
            mt: 4,
            display: "flex",
            alignItems: "center",
        }}
        style={{
            height: "63hv",
            width: "78vw",
            margin: "auto",
            marginTop: "4vh",
            marginBottom: "10vh",
        }}
    >
        <Box
            sx={{
                width: "90%",
                height: "100%",
                backgroundColor: "black",
                color: "white",
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
           <Box sx={{ display: 'flex', fontSize: '10rem', fontWeight: 'bold' }}>
                <Typography
                    component="span"
                    sx={{
                        background: 'linear-gradient(135deg, #00e6ff 0%, #00e6ff 50%, #222222 50%, #000000 100%)',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        fontSize: '18rem',
                        mx: 1,
                    }}
                >
                    4
                </Typography>
                <Typography
                    component="span"
                    sx={{
                        background: 'linear-gradient(135deg, #00e6ff 0%, #00e6ff 50%, #ffffff 50%, #ffffff 100%)',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        fontSize: '18rem',
                        mx: 1,
                    }}
                >
                    0
                </Typography>
                <Typography
                    component="span"
                    sx={{
                        background: 'linear-gradient(135deg, #00e6ff 0%, #00e6ff 50%, #222222 50%, #000000 100%)',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        fontSize: '18rem',
                        mx: 1,
                    }}
                >
                    4
                </Typography>
            </Box>
            <Typography
                sx={{ fontSize: '2rem', mt: 2, color: '#ffffff' }}
            >
                Oops, nothing to see here. Check the URL.
            </Typography> 
        </Box>
        <Box
            sx={{
                //width: { xs: "100%", md: "210px", lg: "502px", xl: "700px" },
                height: { xs: "auto", md: "63vh" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTopRightRadius: 29.5,
                borderBottomRightRadius: 29.5,
                //backgroundImage: `url(${dvImage})`,
                backgroundSize: "cover",
                backgroundPosition: "right",
            }}
        >
        </Box>
    </Box>
    );
}

export default ErrorPage;
