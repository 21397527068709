import React, { createContext, useState } from "react";

const FormContext = createContext();

export const FormProvider = ({ children }) => {
	const [formData, setFormData] = useState({
		practiceName: "",
		practiceAddress: "",
		name: "",
		email: "",
		ehrName: "",
		ehrType: "",
		incentivePayeeName: '', 
  		incentivePayeeAddress: '', 
		payeeAchAccepted: false, 
		userName: "",
		password: "",
		url: "",
	});

	const updateFormData = (name, value) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	return (
		<FormContext.Provider value={{ formData, updateFormData }}>
			{children}
		</FormContext.Provider>
	);
};

export default FormContext;
