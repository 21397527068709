import React, { useContext, useState, useEffect, useRef } from "react";
//import { useNavigate } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";

import FormContext from "../context/FormContext";
import MaskedTextField from "../components/MaskedTextField";

import Info from "@mui/icons-material/Info";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import HelpIcon from "@mui/icons-material/Help";

import successIcon from "../assets/images/successIcon.svg";
//import htmlContent from "../assets/html/ecwCloudHelp.html";
import dvImage from "../assets/images/dvImage.svg";
import eCWCloudHelp from "../assets/pdf/eCW_Cloud_Implementation_Guide.pdf";

const EcwCloudProviderForm = () => {
	// const [formData, setFormData] = useState({
	//   practiceName: 'Smith Family Medicine',
	//   practiceAddress: '123 Abbey Road, Beatlesville, PA 19041',
	//   name: 'Jane Pittman',
	//   email: 'janepittman@sfm.org',
	//   ehrName: 'eClinicalworks',
	//   ehrType: 'Cloud',
	// });
	//const pdfUrl = "https://drive.google.com/file/d/1Rves7bl-mSfsBOrwrqbZ2Ke0Zj1P0Pis/preview";

	const { formData, updateFormData } = useContext(FormContext);
	const [showScrollToIntro, setShowScrollToIntro] = useState(false);
	const [showScrollToForm, setShowScrollToForm] = useState(false);
	const [requesterId, setRequesterId] = useState(null);
	const [message, setMessage] = useState(null);
	const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [openHelpDialog, setOpenHelpDialog] = useState(false);

	const credentialsRef = useRef(null);
	const formRef = useRef(null);
	const introRef = useRef(null);
	const successRequesterId = useRef(null);

	//const navigate = useNavigate();

	// const handleChange = (event) => {
	//   const { name, value } = event.target;
	//   setFormData((prevFormData) => ({
	//     ...prevFormData,
	//     [name]: value,
	//   }));
	// };

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 0.5,
		};

		const introObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setShowScrollToIntro(false);
				}
			});
		}, options);

		const formObserver = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setShowScrollToForm(false);
				}
			});
		}, options);

		const introRefCurrent = introRef.current;
		const formRefCurrent = formRef.current;

		if (introRefCurrent) {
			introObserver.observe(introRefCurrent);
		}

		if (formRefCurrent) {
			formObserver.observe(formRefCurrent);
		}

		return () => {
			if (introRefCurrent) {
				introObserver.unobserve(introRefCurrent);
			}
			if (formRefCurrent) {
				formObserver.unobserve(formRefCurrent);
			}
		};
	}, []);

	useEffect(() => {
		if (message === "Success" && successRequesterId.current) {
			successRequesterId.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
	}, [message]);

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		updateFormData(name, type === 'checkbox' ? checked : value);
	};

	const handleMoveToForm = () => {
		console.log("Lets get started:");
		if (formRef.current) {
			formRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
		setShowScrollToIntro(true);
	};

	const handleScrollToIntro = () => {
		if (introRef.current) {
			introRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
		setShowScrollToIntro(false);
	};

	const handleScrollToForm = () => {
		if (formRef.current) {
			formRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
		setShowScrollToForm(false);
	};

	const handleScrollToCredentials = () => {
		if (credentialsRef.current) {
			credentialsRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
		setShowScrollToForm(true);
	};

	const handleSubmit = async (event) => {
		//console.log("submit 1", event);
		event.preventDefault();
		setIsLoading(true);

		const data = {
			practice_name: formData.practiceName,
			practice_address: formData.practiceAddress,
			requester_name: formData.name,
			requester_email: formData.email,
			ehr: "ECW Cloud",
			ehr_type: "Cloud",
            tags: ["provider"],
			credentials: {
				ecw_url: formData.ecwUrl,
				username: formData.userName,
				password: formData.password,
			},
		};
		console.log("submit 2", data);
		try {
			const response = await axios.post(
				"https://7dmvmfz77k.execute-api.us-west-2.amazonaws.com/support/provider/request",
				data,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			console.log("Form submitted:", response.data);
			setRequesterId(response.data.request_id);
			setMessage(response.data.message);
			setIsSubmitSuccessful(true);
		} catch (error) {
			console.error("Error submitting form:", error);
		} finally {
			setIsLoading(false); // Set loading state to false
		}

		if (credentialsRef.current) {
			credentialsRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
	};

	const handleHelpIconClick = () => {
		setOpenHelpDialog(true);
	};

	const handleHelpDialogClose = () => {
		setOpenHelpDialog(false);
	};

	const handleScreenshotLinkClick = (event) => {
		event.preventDefault();
		setOpenHelpDialog(true);
	};

	return (
		<>
			<Box
				ref={introRef} // Ref for the next section
				sx={{
					//p: 3,
					bgcolor: "black",
					borderRadius: 7.5,
					boxShadow: 3,
					mt: 4,
					display: "flex",
					alignItems: "center",
				}}
				style={{
					height: "63hv",
					width: "78vw",
					margin: "auto",
					marginTop: "4vh",
					marginBottom: "10vh",
				}}
			>
				<Box
					sx={{
						width: "90%",
						height: "100%",
						backgroundColor: "black",
						color: "white",
						p: 3,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography variant="h3" sx={{ fontWeight: "bold" }}>
						datavant
					</Typography>
					<Typography variant="h4" sx={{ fontWeight: "bold", mt: 2 }}>
						Connector Activation
					</Typography>
					<Typography variant="h5" sx={{ color: "#01FFFF", mt: 4 }}>
						ECW CLOUD
					</Typography>
					<Button
						type="button"
						variant="contained"
						style={{
							color: "black",
							fontWeight: "bold",
							backgroundColor: "#01FFFF",
							borderRadius: "10px",
						}}
						sx={{ mt: 8 }}
						onClick={handleMoveToForm}
					>
						Let's get started
					</Button>
				
				</Box>
				<Box
					sx={{
						width: { xs: "100%", md: "210px", lg: "502px", xl: "700px" },
						height: { xs: "auto", md: "63vh" },
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderTopRightRadius: 29.5,
						borderBottomRightRadius: 29.5,
						backgroundImage: `url(${dvImage})`,
						backgroundSize: "cover",
						backgroundPosition: "right",
					}}
				>
					{/* <img
						src={dvImage}
						alt="datavant-logo"
						style={{
							width: "100%",
							height: "auto",
							objectFit: "cover",
						}}
					/> */}
				</Box>
			</Box>

			{showScrollToIntro && (
				<Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
					<Button
						variant="contained"
						style={{
							color: "black",
							backgroundColor: "#01FFFF",
							borderRadius: "10px",
						}}
						onClick={handleScrollToIntro}
					>
						<ArrowUpward />
					</Button>
				</Box>
			)}
			<Box
				ref={formRef} // Ref for the next section
				sx={{
					p: 3,
					bgcolor: "background.paper",
					borderRadius: 7.5,
					boxShadow: 3,
				}}
				style={{
					height: "63hv",
					width: "75vw",
					marginLeft: "auto",
					marginRight: "auto",
					marginTop: "4vh",
					marginBottom: "10vh",
				}}
			>
				<Info
					style={{ fontSize: 25, color: "#01FFFF", marginBottom: "0.50rem" }}
				/>
				<Typography
					color="text.primary"
					variant="subtitle1"
					style={{ fontWeight: "bold", fontSize: "18px" }}
				>
					Let's get to know each other
				</Typography>
				<Typography
					color="text.primary"
					variant="subtitle2"
					style={{ fontWeight: "lighter", marginBottom: "30px", fontSize: "15px" }}
				>
					Automating with digital connections is easy! Simply create new user credentials (see{" "}
					<Link href="#" onClick={handleScreenshotLinkClick} sx={{ color: "#01FFFF", cursor: "pointer", textDecoration: "none" }}>
						here
					</Link>{" "}for screenshots) and fill out the form below.
				
				</Typography>
				<Grid container flexDirection="column" spacing={3}>
					<Grid>
						<TextField
							fullWidth
							label="Practice Name"
							name="practiceName"
							required
							value={formData.practiceName}
							onChange={handleChange}
							disabled={isSubmitSuccessful}
						/>
					</Grid>
					<Grid>
						<TextField
							fullWidth
							label="Practice Address"
							name="practiceAddress"
							required
							value={formData.practiceAddress}
							onChange={handleChange}
							disabled={isSubmitSuccessful}
						/>
					</Grid>
					<Grid>
						<TextField
							fullWidth
							label="Name"
							name="name"
							required
							value={formData.name}
							onChange={handleChange}
							disabled={isSubmitSuccessful}
						/>
					</Grid>
					<Grid>
						<TextField
							fullWidth
							label="Email"
							name="email"
							required
							value={formData.email}
							onChange={handleChange}
							disabled={isSubmitSuccessful}
						/>
					</Grid>
					<Grid>
						<TextField
							fullWidth
							label="EHR"
							name="ehrName"
							value="ECW Cloud"
							disabled={isSubmitSuccessful}
						/>
					</Grid>
					<Grid>
						<TextField
							fullWidth
							label="EHR Type"
							name="ehrType"
							value="Cloud"
							disabled={isSubmitSuccessful}
						/>
					</Grid>
				</Grid>
				<Box sx={{ mt: 5, mb: 5 }} display="flex" justifyContent="center">
					<Button
						type="submit"
						variant="contained"
						style={{
							color: "black",
							fontWeight: "bold",
							backgroundColor: "#01FFFF",
							borderRadius: "10px",
						}}
						onClick={handleScrollToCredentials}
					>
						Continue
					</Button>
				</Box>
			</Box>
			{showScrollToForm && (
				<Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
					<Button
						variant="contained"
						style={{
							color: "black",
							backgroundColor: "#01FFFF",
							borderRadius: "10px",
						}}
						onClick={handleScrollToForm}
					>
						<ArrowUpward />
					</Button>
				</Box>
			)}

			<Box
				ref={credentialsRef} // Ref for the next section
				sx={{
					p: 3,
					bgcolor: "background.paper",
					borderRadius: 7.5,
					boxShadow: 3,
					mt: 3,
				}}
				style={{
					height: "63hv",
					width: "75vw",
					margin: "auto",
					marginTop: "4vh",
					marginBottom: "10vh",
				}}
			>
				<Info
					style={{ fontSize: 25, color: "#01FFFF", marginBottom: "0.50rem" }}
				/>

				<Box display="flex" alignItems="center" mb={3}>
					<Typography
						color="text.primary"
						variant="subtitle1"
						style={{ fontWeight: "bold", fontSize: "18px" }}
					>
						Credentials
					</Typography>
					<IconButton onClick={handleHelpIconClick}>
						<HelpIcon
							style={{ fontSize: 25, color: "#01FFFF", marginRight: "0.5rem" }}
						/>
					</IconButton>
				</Box>

				<form onSubmit={handleSubmit}>
					<Grid container flexDirection="column" spacing={3}>
						<Grid>
							<TextField
								fullWidth
								label="ECW URL"
								name="ecwUrl"
								required
								value={formData.ecwUrl}
								onChange={handleChange}
								disabled={isSubmitSuccessful}
							/>
						</Grid>
						<Grid>
							{/* <TextField
							fullWidth
							label="User Name"
							name="userName"
							type="password"
							required
							value={formData.userName}
							onChange={handleChange}
						/> */}
							<MaskedTextField
								label="User Name"
								name="userName"
								value={formData.userName}
								onChange={handleChange}
								disabled={isSubmitSuccessful}
							/>
						</Grid>
						<Grid>
							{/* <TextField
							fullWidth
							label="Password"
							name="password"
							type="password"
							required
							value={formData.password}
							onChange={handleChange}
						/> */}
							<MaskedTextField
								label="Password"
								name="password"
								value={formData.password}
								onChange={handleChange}
								disabled={isSubmitSuccessful}
							/>
						</Grid>
						{!isSubmitSuccessful && (
							<Box sx={{ mt: 5 }} display="flex" justifyContent="center">
								<Button
									type="submit"
									variant="contained"
									style={{
										color: "black",
										fontWeight: "bold",
										backgroundColor: "#01FFFF",
										borderRadius: "10px",
									}}
									disabled={isLoading} // Disable button when loading
								>
									{isLoading ? (
										<CircularProgress size={24} sx={{ color: "black" }} />
									) : (
										"Submit"
									)}
								</Button>
							</Box>
						)}

						<Box sx={{ mt: 5, mb: 5 }} display="flex" justifyContent="center">
							<Typography
								color="text.primary"
								variant="subtitle1"
								style={{
									fontWeight: "bold",
									marginBottom: "20px",
									fontSize: "16px",
									textAlign: "center",
								}}
							>
								Security is our priority. Rest assured, all information you
								submit is encrypted using industry-standard TLS technology,
								ensuring your data remains secure and confidential.
							</Typography>
						</Box>
					</Grid>
				</form>
			</Box>

			{message === "Success" && (
				<Box
					ref={successRequesterId}
					sx={{
						p: 3,
						bgcolor: "background.paper",
						borderRadius: 7.5,
						boxShadow: 3,
						mt: 4,
						mb: 10,
						textAlign: "center",
					}}
					style={{
						height: "63hv",
						width: "75vw",
						margin: "auto",
						marginTop: "4vh",
						marginBottom: "10vh",
						paddingBottom: "10vh",
					}}
				>
					<img
						src={successIcon}
						className="datavant-logo"
						alt="datavant-logo"
					/>
					<Typography variant="h3" sx={{ fontWeight: "900", fontSize: "38px" }}>
						Request <span style={{ color: "#01FFFF" }}>#{requesterId}</span>{" "}
						Submitted.
					</Typography>
					<Typography
						variant="body1"
						sx={{ mt: 2, fontWeight: "900", fontSize: "20px" }}
					>
						If you have any questions, please contact support@healthjump.com.
					</Typography>
					<Typography
						variant="body1"
						sx={{ fontWeight: "900", fontSize: "20px" }}
					>
						Please reference #{requesterId} in your email.
					</Typography>
				</Box>
			)}

			<Dialog
				open={openHelpDialog}
				onClose={handleHelpDialogClose}
				fullWidth
				maxWidth="md"
			>
				<DialogTitle>
					eClinicalWorks Cloud EHR Connection Implementation Guide
				</DialogTitle>
				<DialogContent sx={{ overflowX: "clip" }}>
					{/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
					<iframe 
					title="eClinicalWorks Cloud EHR Connection Implementation Guide"
                    src={eCWCloudHelp} 
                    width="100%" 
                    height="500px" 
                    style={{ border: 'none' }}
                />
				</DialogContent>
			</Dialog>
		</>
	);
};

export default EcwCloudProviderForm;
