import React, { useCallback, useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from '@mui/material/Backdrop';

import FormContext from "../context/FormContext";
import MaskedTextField from "../components/MaskedTextField";
import ArrowUpward from "@mui/icons-material/ArrowUpward";

import successIcon from "../assets/images/successIcon.svg";
import dvImage from "../assets/images/dvImage.svg";
import eCWCloudHelp from "../assets/pdf/eCW_Cloud_Implementation_Guide.pdf";
import datavantLogo from "../assets/images/datavantLogo.svg";

const EcwCloudForm = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const [showScrollToIntro, setShowScrollToIntro] = useState(false);
  const [showScrollToForm, setShowScrollToForm] = useState(false);
  const [showScrollToPayeeForm, setShowScrollToPayeeForm] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showScrollToCredentials, setShowScrollToCredentials] = useState(false);
  const [requesterId, setRequesterId] = useState(null);
  const [message, setMessage] = useState(null);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const introRef = useRef(null);
  const formRef = useRef(null);
  const payeeFormRef = useRef(null);
  const credentialsRef = useRef(null);
  const successRequesterId = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const introObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowScrollToIntro(false);
        }
      });
    }, options);

    const formObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowScrollToForm(false);
        }
      });
    }, options);

    const payeeFormObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowScrollToPayeeForm(false);
        }
      });
    }, options);

    const credentialsObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShowScrollToCredentials(false);
        }
      });
    }, options);

    const introRefCurrent = introRef.current;
    const formRefCurrent = formRef.current;
    const payeeFormRefCurrent = payeeFormRef.current;
    const credentialsRefCurrent = credentialsRef.current;

    if (introRefCurrent) {
      introObserver.observe(introRefCurrent);
    }

    if (formRefCurrent) {
      formObserver.observe(formRefCurrent);
    }

    if (payeeFormRefCurrent) {
      payeeFormObserver.observe(payeeFormRefCurrent);
    }

    if (credentialsRefCurrent) {
      credentialsObserver.observe(credentialsRefCurrent);
    }

    return () => {
      if (introRefCurrent) {
        introObserver.unobserve(introRefCurrent);
      }
      if (formRefCurrent) {
        formObserver.unobserve(formRefCurrent);
      }
      if (payeeFormRefCurrent) {
        payeeFormObserver.unobserve(payeeFormRefCurrent);
      }
      if (credentialsRefCurrent) {
        credentialsObserver.unobserve(credentialsRefCurrent);
      }
    };
  }, []);

  useEffect(() => {
    if (message === "Success" && successRequesterId.current) {
      successRequesterId.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [message]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    updateFormData(name, type === "checkbox" ? checked : value);
  };

  const handleMoveToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setShowScrollToIntro(true);
  };

  const handleScrollToIntro = () => {
    if (introRef.current) {
      introRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setShowScrollToIntro(false);
  };

  const handleScrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setShowScrollToForm(false);
  };

  const handleScrollToPayeeForm = () => {
    if (payeeFormRef.current) {
      payeeFormRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    setShowScrollToPayeeForm(false);
    setShowScrollToForm(true);
  };

  const handleScrollToCredentials = () => {
    if (credentialsRef.current) {
      credentialsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    setShowScrollToForm(true);
    setShowScrollToPayeeForm(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const data = {
      practice_name: formData.practiceName,
      practice_address: formData.practiceAddress,
      requester_name: formData.name,
      requester_email: formData.email,
      ehr: "ECW Cloud",
      ehr_type: "Cloud",
      payee_name: formData.incentivePayeeName,
      payee_address: formData.incentivePayeeAddress,
      payee_ach_accepted: formData.payeeAchAccepted,
      credentials: {
        ecw_url: formData.ecwUrl,
        username: formData.userName,
        password: formData.password,
      },
    };
    try {
      const response = await axios.post(
        "https://7dmvmfz77k.execute-api.us-west-2.amazonaws.com/support/provider/request",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setRequesterId(response.data.request_id);
      setMessage(response.data.message);
      setIsSubmitSuccessful(true);
      setOpenSuccessModal(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }

    if (credentialsRef.current) {
      credentialsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleHelpDialogClose = () => {
    setOpenHelpDialog(false);
  };

  const handleScreenshotLinkClick = (event) => {
    event.preventDefault();
    setOpenHelpDialog(true);
  };

  const cardStyle = {
    height: "63vh",
    width: "78vw",
    margin: "auto",
    marginTop: "4vh",
    marginBottom: "10vh",
    display: "flex",
    flexDirection: "column",
  };

  const cardContentStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const shouldScroll = urlParams.get('scrollToIntro');
    
    if (shouldScroll === 'true') {
      urlParams.delete('scrollToIntro');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      setTimeout(handleScrollToIntro, 1000);
    }
  }, []);

  const handleCloseSuccessModal = useCallback(() => {
    setIsFadingOut(true);
    setTimeout(() => {
      window.location.href = `${window.location.pathname}?scrollToIntro=true`;
    }, 500); 
  }, []);

  return (
    <>
	<Fade in={!isFadingOut} timeout={500}>
	<div>
      <Box
        ref={introRef}
        sx={{
          bgcolor: "black",
          borderRadius: 7.5,
          boxShadow: 3,
          mt: 4,
          display: "flex",
          alignItems: "center",
        }}
        style={{
          height: "63hv",
          width: "81vw",
          margin: "auto",
          marginTop: "4vh",
          marginBottom: "10vh",
        }}
      >
        <Box
          sx={{
            width: "90%",
            height: "100%",
            backgroundColor: "black",
            color: "white",
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant='h3' sx={{ fontWeight: "bold" }}>
            datavant
          </Typography>
          <Typography variant='h4' sx={{ fontWeight: "bold", mt: 2 }}>
            Connector Activation
          </Typography>
          <Typography variant='h5' sx={{ color: "#01FFFF", mt: 4 }}>
            ECW CLOUD
          </Typography>
          <Button
            type='button'
            variant='contained'
            style={{
              color: "black",
              fontWeight: "bold",
              backgroundColor: "#01FFFF",
              borderRadius: "10px",
            }}
            sx={{ mt: 8 }}
            onClick={handleMoveToForm}
          >
            Let's get started
          </Button>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "210px", lg: "502px", xl: "700px" },
            height: { xs: "auto", md: "63vh" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopRightRadius: 29.5,
            borderBottomRightRadius: 29.5,
            backgroundImage: `url(${dvImage})`,
            backgroundSize: "cover",
            backgroundPosition: "right",
          }}
        ></Box>
      </Box>

      {showScrollToIntro && (
        <Box display='flex' justifyContent='center' sx={{ mt: 2 }}>
          <Button
            variant='contained'
            style={{
              color: "black",
              backgroundColor: "#01FFFF",
              borderRadius: "10px",
            }}
            onClick={handleScrollToIntro}
          >
            <ArrowUpward />
          </Button>
        </Box>
      )}
      <Box
        ref={formRef}
        sx={{
          p: 3,
          bgcolor: "background.paper",
          borderRadius: 7.5,
          boxShadow: 3,
        }}
        style={cardStyle}
      >
        <Box style={cardContentStyle}>
          <img
            src={datavantLogo}
            className='datavant-logo-card'
            alt='datavant-logo'
          />
          <Typography
            color='text.primary'
            variant='subtitle1'
            style={{ fontWeight: "bold", fontSize: "18px" }}
          >
            Let's get to know each other
          </Typography>
          <Typography
            color='text.primary'
            variant='subtitle2'
            style={{
              fontWeight: "lighter",
              marginBottom: "30px",
              fontSize: "15px",
            }}
          >
            Automating with digital connections is easy! Simply create new user
            credentials (see{" "}
            <Link
              href='#'
              onClick={handleScreenshotLinkClick}
              sx={{
                color: "#016cce",
                cursor: "pointer",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              here
            </Link>{" "}
            for screenshots) and fill out the form below.
          </Typography>
          <Grid container flexDirection='column' spacing={3}>
            <Grid>
              <TextField
                fullWidth
                label='Practice Name'
                name='practiceName'
                required
                value={formData.practiceName}
                onChange={handleChange}
                disabled={isSubmitSuccessful}
              />
            </Grid>
            <Grid>
              <TextField
                fullWidth
                label='Practice Address'
                name='practiceAddress'
                required
                value={formData.practiceAddress}
                onChange={handleChange}
                disabled={isSubmitSuccessful}
              />
            </Grid>
            <Grid>
              <TextField
                fullWidth
                label='Name'
                name='name'
                required
                value={formData.name}
                onChange={handleChange}
                disabled={isSubmitSuccessful}
              />
            </Grid>
            <Grid>
              <TextField
                fullWidth
                label='Email'
                name='email'
                required
                value={formData.email}
                onChange={handleChange}
                disabled={isSubmitSuccessful}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 5, mb: 5 }} display='flex' justifyContent='center'>
            <Button
              type='submit'
              variant='contained'
              style={{
                color: "black",
                fontWeight: "bold",
                backgroundColor: "#01FFFF",
                borderRadius: "10px",
              }}
              onClick={handleScrollToPayeeForm}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>

      {showScrollToForm && (
        <Box display='flex' justifyContent='center' sx={{ mt: 2 }}>
          <Button
            variant='contained'
            style={{
              color: "black",
              backgroundColor: "#01FFFF",
              borderRadius: "10px",
            }}
            onClick={handleScrollToForm}
          >
            <ArrowUpward />
          </Button>
        </Box>
      )}

      <Box
        ref={payeeFormRef}
        sx={{
          p: 3,
          bgcolor: "background.paper",
          borderRadius: 7.5,
          boxShadow: 3,
          mt: 3,
        }}
        style={cardStyle}
      >
        <Box style={cardContentStyle}>
          <img
            src={datavantLogo}
            className='datavant-logo-card'
            alt='datavant-logo'
          />
          <Typography
            color='text.primary'
            variant='subtitle1'
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              marginBottom: "-52px",
            }}
          >
            Incentive Payee
          </Typography>
          <Typography
            color='text.primary'
            variant='subtitle1'
            style={{
              fontWeight: "normal",
              fontSize: "17px",
              marginBottom: "-26px",
            }}
          >
            *Please provide the following information for incentive payments:
          </Typography>
          <Grid container flexDirection='column' spacing={3}>
            <Grid>
              <TextField
                fullWidth
                label='Incentive Payee Name'
                name='incentivePayeeName'
                value={formData.incentivePayeeName}
                onChange={handleChange}
                disabled={isSubmitSuccessful}
              />
            </Grid>
            <Grid>
              <TextField
                fullWidth
                label='Incentive Payee Address'
                name='incentivePayeeAddress'
                value={formData.incentivePayeeAddress}
                onChange={handleChange}
                disabled={isSubmitSuccessful}
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.payeeAchAccepted}
                    onChange={handleChange}
                    name='payeeAchAccepted'
                    color='primary'
                    disabled={isSubmitSuccessful}
                  />
                }
                label='Payee ACH Accepted'
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 5, mb: 5 }} display='flex' justifyContent='center'>
            <Button
              type='button'
              variant='contained'
              style={{
                color: "black",
                fontWeight: "bold",
                backgroundColor: "#01FFFF",
                borderRadius: "10px",
              }}
              onClick={handleScrollToCredentials}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>

      {showScrollToPayeeForm && (
        <Box display='flex' justifyContent='center' sx={{ mt: 2 }}>
          <Button
            variant='contained'
            style={{
              color: "black",
              backgroundColor: "#01FFFF",
              borderRadius: "10px",
            }}
            onClick={handleScrollToPayeeForm}
          >
            <ArrowUpward />
          </Button>
        </Box>
      )}

      <Box
        ref={credentialsRef}
        sx={{
          p: 3,
          bgcolor: "background.paper",
          borderRadius: 7.5,
          boxShadow: 3,
          mt: 3,
        }}
        style={cardStyle}
      >
        <Box style={cardContentStyle}>
          <img
            src={datavantLogo}
            className='datavant-logo-card'
            alt='datavant-logo'
          />
          <Typography
            color='text.primary'
            variant='subtitle1'
            style={{ fontWeight: "bold", fontSize: "18px" }}
          >
            Credentials
          </Typography>
          <Typography
            color='text.primary'
            variant='subtitle2'
            style={{
              fontWeight: "lighter",
              marginBottom: "30px",
              fontSize: "15px",
            }}
          >
            Automating with digital connections is easy! Simply create new user
            credentials (see{" "}
            <Link
              href='#'
              onClick={handleScreenshotLinkClick}
              sx={{
                color: "#016cce",
                cursor: "pointer",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              here
            </Link>{" "}
            for screenshots) and fill out the form below.
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container flexDirection='column' spacing={3}>
              <Grid>
                <TextField
                  fullWidth
                  label='ECW URL'
                  name='ecwUrl'
                  required
                  value={formData.ecwUrl}
                  onChange={handleChange}
                  disabled={isSubmitSuccessful}
                />
              </Grid>
              <Grid>
                <MaskedTextField
                  label='User Name'
                  name='userName'
                  value={formData.userName}
                  onChange={handleChange}
                  disabled={isSubmitSuccessful}
                />
              </Grid>
              <Grid>
                <MaskedTextField
                  label='Password'
                  name='password'
                  value={formData.password}
                  onChange={handleChange}
                  disabled={isSubmitSuccessful}
                />
              </Grid>
              {!isSubmitSuccessful && (
                <Box sx={{ mt: 5 }} display='flex' justifyContent='center'>
                  <Button
                    type='submit'
                    variant='contained'
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      backgroundColor: "#01FFFF",
                      borderRadius: "10px",
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} sx={{ color: "black" }} />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              )}

              <Box sx={{ mt: 5, mb: 5 }} display='flex' justifyContent='center'>
                <Typography
                  color='text.primary'
                  variant='subtitle1'
                  style={{
                    fontWeight: "bold",
                    marginBottom: "20px",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Security is our priority. Rest assured, all information you
                  submit is encrypted using industry-standard TLS technology,
                  ensuring your data remains secure and confidential.
                </Typography>
              </Box>
            </Grid>
          </form>
        </Box>
      </Box>

      <Modal
        open={openSuccessModal}
        closeAfterTransition
		slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
			sx: {
				bgcolor: '#010100'
			  },
          },
        }}
      >
        <Fade in={openSuccessModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "63vh",
              width: "78vw",
              bgcolor: "background.paper",
              borderRadius: 7.5,
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              textAlign: "center",
            }}
          >
            <img
              src={successIcon}
              className='confirmation-logo'
              alt='datavant-alt-logo'
            />
            <Typography
              variant='h3'
              sx={{ fontWeight: "900", fontSize: "38px" }}
            >
              Request <span style={{ color: "#016cce",  }}>#{requesterId}</span>{" "}
              Submitted.
            </Typography>
            <Typography
              variant='body1'
              sx={{ mt: 2, fontWeight: "900", fontSize: "20px" }}
            >
              If you have any questions, please contact support@healthjump.com.
              <br />
              Please reference #{requesterId} in your email.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleCloseSuccessModal}
              variant='contained'
              sx={{
                margin: "auto",
                fontWeight: "bold",
                color: "black",
                bgcolor: "#01FFFF",
                borderRadius: "10px",
                width: "130px",
                '&:hover': {
                  bgcolor: '#00cccc', 
                  color: '#010100',  
                      
                }
              }}
            >
              Close
            </Button>
            </Box> 
          </Box>
        </Fade>
      </Modal>

      <Dialog
        open={openHelpDialog}
        onClose={handleHelpDialogClose}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>
          eClinicalWorks Cloud EHR Connection Implementation Guide
        </DialogTitle>
        <DialogContent sx={{ overflowX: "clip" }}>
          <iframe
            title='eClinicalWorks Cloud EHR Connection Implementation Guide'
            src={eCWCloudHelp}
            width='100%'
            height='500px'
            style={{ border: "none" }}
          />
        </DialogContent>
      </Dialog>
	  </div>
	  </Fade>
    </>
  );
};

export default EcwCloudForm;
