import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FormProvider } from "./context/FormContext";
import EcwCloudForm from "./view/ecwcloud";
import EcwCloudProviderForm from "./view/ecwcloudprovider";
import NextGenForm from "./view/nextgen";
import NextGenProviderForm from "./view/nextgenprovider";
import Home from "./view/home";
import ErrorPage from "./view/error";

import datavantLogo from "./assets/images/datavantLogo.svg";
import "./assets/css/App.css";

function App() {
	return (
		<FormProvider>
			<Router>
				<div className="App">
					<img
						src={datavantLogo}
						className="datavant-logo"
						alt="datavant-logo"
					/>
					<header className="App-header">
					</header>
					<main className="app-main">
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/ecwcloud" element={<EcwCloudForm />} />
							<Route path="/ecwcloud_provider" element={<EcwCloudProviderForm />} />
							<Route path='/nextgenoffice' element={<NextGenForm />} />
							<Route path='/nextgenoffice_provider' element={<NextGenProviderForm />} />
							<Route path="*" element={<ErrorPage />} />
						</Routes>
					</main>
				</div>
			</Router>			
		</FormProvider>
	);
}

export default App;
